import { getUser, getListPurchaseHistory, update, updatePhoto, post } from "../../requests"

export const getUserById = async (userId) => {
    return await getUser(userId)
}

export const getPurchaseHistory = async (userId, token) => {
    return await getListPurchaseHistory(userId, token)
}

export const updateUser = async (userId, token, user) => {
    return await update(userId, token, user)
}

export const updatePicture = async (userId, token, image) => {
    return await updatePhoto(userId, token, image)
}


export const deleteAccount = async ({userId}) => {
    try {
       return await post({data: "account deletion"}, `user/delete/${userId}`)
    } catch (error) {
        throw error
    }
}
